<template>
    <div class="messages">
        <h1 class="subheading grey--text">messages</h1>
        <main class="my-5 mx-10">
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore accusamus, obcaecati eos maiores cupiditate repudiandae temporibus magnam porro aut ea, esse ut velit error, aperiam laudantium necessitatibus a officiis recusandae?</p>
        </main>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>